import React from 'react'
import { Grid, List, Image, Header } from 'semantic-ui-react'
import profilePic from './IMG_1688.JPG'

export default function Info () {
  return (
    <Grid divided stackable verticalAlign='middle' padded>
      <Header as='h2' style={{ marginBottom: '0px' }}>The Basics</Header>
      <Grid.Row style={{ marginBottom: '0px' }}>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Header as='h5'>
                Name
              </List.Header>
              <List.Content>
                Dustin DeShane
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Header as='h5'>
                Programming Languages
              </List.Header>
              <List.Content>
                Python<br />
                Javascript<br />
                HTML5<br />
                CSS3
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Header as='h5'>
                Libraries
              </List.Header>
              <List.Content>
                Django<br />
                VueJS<br />
                ReactJS<br />
                NodeJS<br />
                Express<br />
                SemanticUI
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <List.Header as='h5'>
                Databases
              </List.Header>
              <List.Content>
                MongoDB<br />
                SQLite
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Header as='h5'>
                Miscellaneous
              </List.Header>
              <List.Content>
                Git<br />
                Github<br />
                Windows<br />
                Linux
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Header as='h5'>
                Other Skills
              </List.Header>
              <List.Content>
                Electronics<br />
                Troubleshooting<br />
                Validation<br />
                Automated Testing<br />
                Calibration
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={8}>
          <Image src={profilePic} alt='Profile Picture' size='large' centered rounded bordered />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as='h2'>About Me</Header>
          Welcome to my portfolio. My name is Dustin DeShane.  I spent six years in the Navy as a Nuclear Reactor Operator and another six years working with electronics since then.  I've been working my way towards a career change for years now and I'm looking forward to a future in software development. Two coding boot camps learning a couple different stacks and languages later, I am ready to embrace that transition.  Please checkout my projects below.
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
