import React from 'react'
import { Image, Header } from 'semantic-ui-react'
import HomrPlaceholder from './homrapp.png'

export default function CurrentProject () {
  return (
    <div>
      <div>
        <Header as='h2'>Past Projects</Header>
        <Image src={HomrPlaceholder} fluid rounded /><br />
      </div>
      <div>
        <Header as='h2'>HOMR</Header>
        HOMR is an app designed to assist firefighters and charitable organizations in helping the homeless population in the Bay Area. Prior to this application, records were being kept in a non-centralized manner through various spreadsheets and notes. The implementation of this app leads to a centralized approach that allows city officials and firefighters to better allocate resources and budgets to best serve those in need.
      </div>

    </div>
  )
}