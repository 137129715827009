import React from 'react'
import { Image, Header } from 'semantic-ui-react'
import ChainChaser from './chainchaserapp.png'

export default function CurrentProject () {
  return (
    <div>
      <div>
        <Header as='h2'>Current Project - Chain Chaser</Header>
        <Image src={ChainChaser} fluid rounded /><br />
      </div>
      <div>
        <Header as='h2'>Description</Header>
        Chain Chaser is a disc golf helper app.  It will feature active scorecards based upon course being played, user course addition, performance tracking, bag inventory, and more. The final goal is to have an all-in-one resource for disc golfers to get into and keep track of their game as easily as possible.  
      </div>

    </div>
  )
}