import React from 'react'
import './App.css'
import logo from './deshanedesignslogo1.png'
import Info from './Info'
import CurrentProject from './CurrentProject'
import PastProjects from './PastProjects'
import 'semantic-ui-css/semantic.min.css'

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div id='root' style={{margin: '0 auto', width: '75%'}}>
        <div id='header' style={{backgroundColor: 'black'}}>
          <img alt='Logo' src={logo} height='40px' style={{maxWidth: '100%'}} />
        </div>
        <div style={{backgroundColor: 'skyblue', border: '1px solid black' }}>
          <div style={{ margin: '0 auto', width: '95%' }}>
            <Info />
          </div>
        </div>
        <div style={{backgroundColor: 'orange', border: '1px solid black' }}>
          <div style={{ margin: '0 auto', width: '95%' }}>
            <CurrentProject />
          </div>
        </div>
        <div style={{backgroundColor: 'lime', border: '1px solid black' }}>
          <div style={{ margin: '0 auto', width: '95%' }}>
            <PastProjects />
          </div>
        </div>
        <div style={{backgroundColor: 'black', border: '1px solid black', height: '45px' }}>
          <div style={{ margin: '0 auto', width: '95%', textAlign: 'center' }}>
            <a href='mailto:dxdeshane@gmail.com' style={{ color: 'white' }}>Contact</a>
          </div>
        </div>
      </div>
    )
  }
}

export default App;
